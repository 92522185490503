import { gql } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { getTime } from '../utils/getTime';
import { SequenceEpisodes } from '../utils/getSequenceEpisodes';
import { formatSeconds } from '../utils/formatSeconds';

export const EDIT_INTERVAL_ANNOTATION = gql`
  mutation EditInterval($input: EditIntervalInput!) {
    editInterval(input: $input) {
      error
      success
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 550,
  },
  cell: {
    border: 'none',
  },
  cellSummary: {
    borderBottom: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type TachycardiaTableProps = {
  episodes: SequenceEpisodes['tachycardiaEpisodes'];
  edit?: boolean;
};

export function TachycardiaTable(props: TachycardiaTableProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { episodes, edit } = props;

  const getDuration = (from: number, to: number) => (to - from) / 200;

  const getTotalDuration = () => {
    const time = episodes?.reduce((acc, ep) => {
      if (ep.isValid) {
        return acc + getDuration(ep.start, ep.end);
      }

      return acc;
    }, 0);

    const formattedTime = formatSeconds(time);

    return formattedTime;
  };

  const renderEmpty = () => (
    <TableBody>
      <TableRow>
        <TableCell className={classes.cell}>{t('tachycardiaTable.noneDetected')}</TableCell>
      </TableRow>
    </TableBody>
  );

  const episodesToShow = edit ? episodes : episodes?.filter((ep) => ep.isValid);

  const renderFull = () => (
    <>
      <TableHead>
        <TableRow>
          <TableCell>{t('tachycardiaTable.columns.from')}</TableCell>
          <TableCell>{t('tachycardiaTable.columns.to')}</TableCell>
          <TableCell>{t('tachycardiaTable.columns.duration')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {episodesToShow?.map((ep) => {
          const duration = getDuration(ep.start, ep.end);
          const formattedDuration = formatSeconds(duration);

          return (
            <TableRow key={ep.id}>
              <TableCell className={classes.cell}>
                {getTime(ep.recordingStartedAt ?? 0, ep.start)}
              </TableCell>
              <TableCell className={classes.cell}>
                {getTime(ep.recordingStartedAt ?? 0, ep.end)}
              </TableCell>
              <TableCell className={classes.cell}>{formattedDuration}</TableCell>
            </TableRow>
          );
        })}
        <TableRow>
          <TableCell className={classes.cell} colSpan={1} />
          <TableCell className={classes.cellSummary}>{t('tachycardiaTable.columns.total')}</TableCell>
          <TableCell className={classes.cellSummary}>{getTotalDuration()}</TableCell>
        </TableRow>
      </TableBody>
    </>
  );

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        {t('tachycardiaTable.title')}
      </Typography>
      <Table className={classes.table} size="small">
        {episodesToShow?.length ? renderFull() : renderEmpty()}
      </Table>
    </Container>
  );
}
